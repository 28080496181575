import Vue from 'vue'
import VueRouter from 'vue-router'
import parsedRoutes from '@/global/loaders/routes'
import NotFoundView from '@/global/views/NotFoundView'
import MaintenanceView from '@/global/views/MaintenanceView'
import store from '@/global/store'

Vue.use(VueRouter)

const routes = [
  ...parsedRoutes,
  {
    path: '/maintenance-mode',
    name: 'MaintenanceMode',
    component: MaintenanceView,
    meta: {
      title: 'Maintenance Mode',
      exclude: true,
      layout: 'login',
      requiresAuth: false
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundView,
    meta: {
      title: '404',
      exclude: true,
      layout: 'login',
      requiresAuth: false
    }
  }
]

function checkIfUserHasPermission (to, userMenuComponentNames) {
  const needsPermissionRoutes = to.matched.some(record => record.meta.needsPermission)
  const matchedName = to.matched[0].name

  return needsPermissionRoutes && !userMenuComponentNames.includes(matchedName)
}

async function beforeEach (to, from, next) {
  let isAuthenticated = store.getters['base/config/isAuthenticated']
  if (!isAuthenticated) {
    const forceRecreation = store.getters['base/config/isPartiallyInitialized']
    if (to.path !== '/maintenance-mode') {
      await store.dispatch('base/config/fetch', { forceRecreation })
    }
    isAuthenticated = store.getters['base/config/isAuthenticated']
  }

  const userMenuComponentNames = store.getters['base/config/userMenuComponentNames']

  // If the target route is /login and the user is not authenticated, allow the navigation to proceed to the /login route.
  if (to.name === 'Login' && !isAuthenticated) {
    next()
    return
  }

  // If the target route is /login and the user is authenticated, redirect them to the home (/) route.
  if (to.name === 'Login' && isAuthenticated) {
    next({ name: 'Home' })
    return
  }

  // If the user tries to access a route that requires authentication, and it's not authenticated, redirect them to the /login route.
  if ((to.matched.some(({ meta: { requiresAuth = false } }) => requiresAuth === true) || to.matched.length) && !isAuthenticated) {
    next({ name: 'Login' })
    return
  }

  // If the target route is within a special set of routes that require permission and the user lacks the necessary permissions, redirect them to the NotFound page.
  if (checkIfUserHasPermission(to, userMenuComponentNames)) {
    next({ name: 'NotFound' })
    return
  }

  // If none of the above conditions are met, allow the navigation to proceed to the target route.
  next()
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(beforeEach)

export default router

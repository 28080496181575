import common from '../common'
import { api } from '@/global/services/api'

export default {
  name: 'basicInfoConfig',

  mixins: [
    common
  ],

  data () {
    return {
      basicInfoPermissions: {}
    }
  },

  computed: {
    basicInfo () {
      return {
        title: this.$t('fleet/vehicle.basic_info'),
        key: 'basic-info',
        apiConfig: {
          post: {
            module: 'fleet',
            route: `travel-documents/prl-cargo/basic-info/${this.$route.params.id}`,
            method: 'post'
          },
          get: {
            module: 'fleet',
            route: `travel-documents/prl-cargo/basic-info/${this.$route.params.id}`,
            method: 'get'
          }
        },
        hiddenFormKeys: [
          'vehicle_id',
          'trailer_id',
          'crew_members'
        ],
        permissions: this.basicInfoPermissions,
        onDataReceived: (data) => {
          const { meta } = data || {}
          const { permissions } = meta || {}
          this.$set(this.basicInfoPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
          this.$set(this.basicInfoPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
        },
        additionalSetup: async (event) => await this.callBasicInfoAdditionalSetup(event),
        additionalDataManipulation: (data) => this.handleAdditionalDataManipulation(data),
        afterSave: (instance, data) => this.basicInfoAfterSave(instance, data),
        afterCancel: (instance) => this.basicInfoAfterCancel(instance),
        tabContent: [
          // PRL data card
          {
            title: this.$t('fleet/vehicle.basic_info'),
            subTitle: this.$t('fleet/documents/prl_cargo.data_of_prl'),
            component: this.columnsContentComponent,
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            additional_buttons: [
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                prependIcon: 'mdi-file-pdf-box',
                click: (event) => this.onBasicInfoViewPdf(event)
              },
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.change_status'),
                prependIcon: 'mdi-swap-horizontal',
                visible: (event) => {
                  const { permissions } = event || {}

                  return permissions && permissions.canChangeStatus
                },
                click: (event) => this.onChangeStatus(event, 'basic-info')
              }
            ],
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'foreign_code',
                    label: this.$t('fleet/documents/prl_cargo.prl_no'),
                    row: 1,
                    column: 1,
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'date_time',
                    label: this.$t('fleet/documents/prl_cargo.creation_date'),
                    type: 'datetime-picker',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY. HH:mm',
                    placeholder: 'DD.MM.YYYY. HH:mm',
                    row: 1,
                    column: 2,
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'status_name',
                    label: this.$t('fleet/vehicle.status'),
                    options: {
                      custom_display_value: (value) => {
                        return value ? this.$t(value) : ''
                      }
                    },
                    row: 1,
                    column: 3,
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'transport_type',
                    label: this.$t('fleet/documents/prl_cargo.transport_type'),
                    options: {
                      custom_display_value: (value) => {
                        return value ? this.$t(value) : ''
                      }
                    },
                    creatable: true,
                    row: 1,
                    column: 4,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'company_name',
                    label: this.$t('fleet/documents/prl_cargo.company_name_and_address'),
                    creatable: true,
                    row: 2,
                    column: 1,
                    colSpan: 2,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'organizational_unit',
                    label: this.$t('fleet.organizational_unit'),
                    creatable: true,
                    row: 2,
                    column: 3,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'department',
                    label: this.$t('fleet.department'),
                    creatable: true,
                    row: 2,
                    column: 4,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'cost_location',
                    label: this.$t('fleet/documents/prl_cargo.cost_location'),
                    placeholder: this.$t('fleet/documents/prl_cargo.cost_location_placeholder'),
                    creatable: true,
                    row: 3,
                    column: 1,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'relation',
                    label: this.$t('fleet/documents/prl_cargo.relation_or_direction'),
                    placeholder: this.$t('fleet/documents/prl_cargo.relation_or_direction_placeholder'),
                    creatable: true,
                    row: 3,
                    column: 2,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          // Crew members card
          {
            subTitle: this.$t('fleet/documents/prl_cargo.crew_members'),
            component: this.columnsContentComponent,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'driver_name',
                    label: this.$t('fleet/vehicle.driver'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'crew_member_1',
                    label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 1),
                    placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'people',
                      route: 'people/autocomplete',
                      fetchInitial: false,
                      returnValue: 'object'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'crew_member_2',
                    label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 2),
                    placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 2),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'people',
                      route: 'people/autocomplete',
                      fetchInitial: false,
                      returnValue: 'object'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'crew_member_3',
                    label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 3),
                    placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'people',
                      route: 'people/autocomplete',
                      fetchInitial: false,
                      returnValue: 'object'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          // Vehicle and trailer data card
          {
            subTitle: this.$t('fleet/documents/prl_cargo.vehicle_and_trailer_data'),
            component: this.columnsContentComponent,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'vehicle_brand',
                    label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'vehicle_registration',
                    label: this.$t('fleet/vehicle.registration'),
                    placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                    type: 'autocomplete',
                    onSelect: (event) => this.onVehicleRegistrationSelect(event),
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/get-vehicle',
                      custom_display_value: (item) => {
                        if (item && item.vehicle_registration) {
                          return item.vehicle_registration
                        }
                      },
                      additionalQueryParams: (event) => {
                        this.vehicleRegistrationAutocompleteField = event
                        return {
                          by: 'registration',
                          vehicle_type: 'vehicle',
                          document_type: 'PRL_CARGO'
                        }
                      },
                      returnValue: 'object',
                      form_value: 'vehicle_registration'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_garage_number',
                    label: this.$t('fleet/vehicle_attribute.garage_number'),
                    placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                    type: 'autocomplete',
                    onSelect: (event) => this.onVehicleGarageNumberSelect(event),
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/get-vehicle',
                      custom_display_value: (item) => {
                        if (item && item.vehicle_garage_number) {
                          return item.vehicle_garage_number
                        }
                      },
                      additionalQueryParams: (event) => {
                        this.vehicleGarageNumberAutocompleteField = event
                        return {
                          by: 'garage_number',
                          vehicle_type: 'vehicle',
                          document_type: 'PRL_CARGO'
                        }
                      },
                      returnValue: 'object',
                      form_value: 'vehicle_garage_number'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_brand',
                    label: this.$t('fleet/documents/prl_cargo.trailer_brand'),
                    creatable: true,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'trailer_registration',
                    label: this.$t('fleet/vehicle.registration'),
                    placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                    type: 'autocomplete',
                    onSelect: (event) => this.onTrailerRegistrationSelect(event),
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/get-vehicle',
                      custom_display_value: (item) => {
                        if (item && item.trailer_registration) {
                          return item.trailer_registration
                        }
                      },
                      additionalQueryParams: {
                        by: 'registration',
                        vehicle_type: 'trailer',
                        document_type: 'PRL_CARGO'
                      },
                      returnValue: 'object',
                      form_value: 'trailer_registration'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_garage_number',
                    label: this.$t('fleet/vehicle_attribute.garage_number'),
                    placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                    type: 'autocomplete',
                    onSelect: (event) => this.onTrailerGarageNumberSelect(event),
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/get-vehicle',
                      custom_display_value: (item) => {
                        if (item && item.trailer_garage_number) {
                          return item.trailer_garage_number
                        }
                      },
                      additionalQueryParams: {
                        by: 'garage_number',
                        vehicle_type: 'trailer',
                        document_type: 'PRL_CARGO'
                      },
                      returnValue: 'object',
                      form_value: 'trailer_garage_number'
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          // Signature card
          {
            component: this.columnsContentComponent,
            fullWidth: true,
            subTitle: this.$t('fleet/documents/prl_cargo.signature_title'),
            rows: [
              {
                type: 'fields',
                fields: [
                  {
                    type: 'content-with-buttons',
                    label: this.$t('fleet/documents/prl_cargo.drivers_signature'),
                    contentStack: 'horizontal',
                    buttonsSide: 'right',
                    buttonsStack: 'vertical',
                    buttons: [
                      {
                        type: 'save',
                        label: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.inspection_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                        },
                        disabled: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && (!!modifiedFormData.inspection_signature_image || !modifiedFormData.inspection_can_sign)
                        },
                        prependIcon: (event) => {
                          const { modifiedFormData } = event || {}
                          return modifiedFormData && !!modifiedFormData.inspection_signature_image ? 'mdi-check' : 'mdi-draw'
                        },
                        style: {
                          width: '96px'
                        },
                        prependIconStyle: {
                          color: '#FFFFFF !important',
                          marginRight: '4px',
                          fontSize: '19px'
                        },
                        click: (event) => this.sign(event, 'PRL_CARGO_INSPECTION', true)
                      }
                    ],
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      minWidth: '256px',
                      maxWidth: '256px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px',
                      marginRight: '10px'
                    },
                    key: 'inspection_signature_image',
                    creatable: false,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    async callBasicInfoAdditionalSetup (event) {
      const { initialAutocompletes, formDataForSend, updateData } = event || {}

      const { data: peopleData, has_more: hasMore = false } = await api().people.get('people/autocomplete', {})
      if (peopleData && peopleData.length && initialAutocompletes) {
        this.$set(initialAutocompletes, 'crew_member_1', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_2', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_3', {
          data: peopleData,
          hasMore: hasMore
        })
      }

      if (formDataForSend && formDataForSend.vehicle_id) {
        this.vehicleRegistrationAutocompleteField.pickedId = formDataForSend.vehicle_id
        this.vehicleGarageNumberAutocompleteField.pickedId = formDataForSend.vehicle_id
      }

      if (updateData && typeof updateData === 'function') {
        this.updateFormData(updateData, {
          crew_member_1: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[0] ? formDataForSend.crew_members[0] : null,
          crew_member_2: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[1] ? formDataForSend.crew_members[1] : null,
          crew_member_3: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[2] ? formDataForSend.crew_members[2] : null
        })
      }
    },

    basicInfoAfterSave (instance, data) {
      const { formDataForSend, updateData } = instance || {}

      if (updateData && typeof updateData === 'function') {
        this.updateFormData(updateData, {
          crew_member_1: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[0] ? formDataForSend.crew_members[0] : null,
          crew_member_2: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[1] ? formDataForSend.crew_members[1] : null,
          crew_member_3: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[2] ? formDataForSend.crew_members[2] : null
        })
      }
    },

    basicInfoAfterCancel (instance) {
      const { formDataForSend, updateData } = instance || {}

      if (updateData && typeof updateData === 'function') {
        this.updateFormData(updateData, {
          crew_member_1: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[0] ? formDataForSend.crew_members[0] : null,
          crew_member_2: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[1] ? formDataForSend.crew_members[1] : null,
          crew_member_3: formDataForSend && formDataForSend.crew_members && formDataForSend.crew_members[2] ? formDataForSend.crew_members[2] : null
        })
      }
    },

    onBasicInfoViewPdf (event) {
    },

    sign (event, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    }
  }
}

export default {
  name: 'garageConfig',

  data () {
    return {
      garagePermissions: {}
    }
  },

  computed: {
    garage () {
      return {
        title: this.$t('fleet/documents/prl_cargo.garage'),
        key: 'garage',
        apiConfig: {
          post: {
            module: 'fleet',
            route: `travel-documents/prl-cargo/garage/${this.$route.params.id}`,
            method: 'post'
          },
          get: {
            module: 'fleet',
            route: `travel-documents/prl-cargo/garage/${this.$route.params.id}`,
            method: 'get'
          }
        },
        fileKeys: [
          'departure_signature',
          'arrival_signature'
        ],
        permissions: this.garagePermissions,
        onDataReceived: (data) => {
          const { meta } = data || {}
          const { permissions } = meta || {}
          this.$set(this.garagePermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
        },
        tabContent: [
          // Departure card
          {
            title: this.$t('fleet/documents/prl_cargo.garage'),
            subTitle: this.$t('fleet/documents/prl_cargo.departure'),
            component: this.columnsContentComponent,
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            fullWidth: true,
            insideCardButtons: {
              position: 'right',
              buttons: [
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                  },
                  visible: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_can_sign
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image
                  },
                  color: this.$route.query.departure_color ?? '',
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  click: (event) => this.garageSign(event, 'PRL_CARGO_DEPARTURE', true)
                },
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                  },
                  visible: () => {
                    const { canEdit } = this.garagePermissions || {}
                    return canEdit
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image ? 'mdi-check' : 'mdi-send'
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onDepartureSendForSignature(event)
                },
                {
                  type: 'base',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.third_party_signature')
                  },
                  visible: (event) => {
                    const { canEdit } = this.garagePermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.departure_signature_image
                  },
                  color: '#65AD45',
                  style: {
                    color: '#FFFFFF'
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onDepartureThirdPartySignature(event)
                }
              ]
            },
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'departure_date',
                    label: this.$t('fleet/documents/prl_cargo.date'),
                    type: 'datetime-picker',
                    subType: 'date',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY.',
                    placeholder: 'DD.MM.YYYY.',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'departure_time',
                    label: this.$t('fleet/documents/prl_cargo.time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'departure_odometer',
                    label: this.$t('fleet/documents/prl_cargo.kilometer_status'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    type: 'content-with-buttons',
                    key: 'departure_signature_image',
                    label: this.$t('fleet/documents/prl_cargo.goalkeeper_signature'),
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px'
                    },
                    creatable: false,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'departure_signed_at',
                    label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                    type: 'datetime-picker',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY HH:mm',
                    placeholder: 'DD.MM.YYYY HH:mm',
                    creatable: false,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'departure_signature_name',
                    label: this.$t('fleet/documents/prl_cargo.signature_full_name'),
                    creatable: false,
                    editable: false,
                    visible: true
                  }
                ]
              }
            ]
          },
          // Arrival card
          {
            subTitle: this.$t('fleet/documents/prl_cargo.arrival'),
            component: this.columnsContentComponent,
            fullWidth: true,
            insideCardButtons: {
              position: 'right',
              buttons: [
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                  },
                  visible: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_can_sign
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image
                  },
                  color: this.$route.query.arrival_color ?? '',
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  click: (event) => this.garageSign(event, 'PRL_CARGO_ARRIVAL', true)
                },
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                  },
                  visible: () => {
                    const { canEdit } = this.garagePermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image ? 'mdi-check' : 'mdi-send'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onArrivalSendForSignature(event)
                },
                {
                  type: 'base',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.third_party_signature')
                  },
                  visible: (event) => {
                    const { canEdit } = this.garagePermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.arrival_signature_image
                  },
                  color: '#65AD45',
                  style: {
                    color: '#FFFFFF'
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onArrivalThirdPartySignature(event)
                }
              ]
            },
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'arrival_date',
                    label: this.$t('fleet/documents/prl_cargo.date'),
                    type: 'datetime-picker',
                    subType: 'date',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY.',
                    placeholder: 'DD.MM.YYYY.',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'arrival_time',
                    label: this.$t('fleet/documents/prl_cargo.time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'arrival_odometer',
                    label: this.$t('fleet/documents/prl_cargo.kilometer_status'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    type: 'content-with-buttons',
                    key: 'arrival_signature_image',
                    label: this.$t('fleet/documents/prl_cargo.goalkeeper_signature'),
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px'
                    },
                    creatable: false,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'arrival_signed_at',
                    label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                    type: 'datetime-picker',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY HH:mm',
                    placeholder: 'DD.MM.YYYY HH:mm',
                    creatable: false,
                    editable: false,
                    visible: true
                  },
                  {
                    key: 'arrival_signature_name',
                    label: this.$t('fleet/documents/prl_cargo.signature_full_name'),
                    creatable: false,
                    editable: false,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onDepartureSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_DEPARTURE')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    // Departure third party signature
    onDepartureThirdPartySignature (event) {
      const { formData } = event || {}
      if (formData) {
        this.$set(this.thirdPartySignatureDialogConfig, 'title', this.$t('fleet/documents/prl_cargo.add_signatory_information'))
        this.$set(this.thirdPartySignatureDialogConfig, 'signatureType', 'PRL_CARGO_DEPARTURE')
        this.$set(this.thirdPartySignatureDialogConfig, 'apiConfig', {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/third-party-signature'
        })
        this.$set(this.thirdPartySignatureDialogConfig, 'instance', event?.indexPage)
        this.$set(this.thirdPartySignatureDialogConfig, 'show', true)
      }
    },

    garageSign (event, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    },

    onArrivalSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_ARRIVAL')
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    // Arrival third party signature
    onArrivalThirdPartySignature (event) {
      const { formData } = event || {}
      if (formData) {
        this.$set(this.thirdPartySignatureDialogConfig, 'title', this.$t('fleet/documents/prl_cargo.add_signatory_information'))
        this.$set(this.thirdPartySignatureDialogConfig, 'signatureType', 'PRL_CARGO_ARRIVAL')
        this.$set(this.thirdPartySignatureDialogConfig, 'apiConfig', {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/third-party-signature'
        })
        this.$set(this.thirdPartySignatureDialogConfig, 'instance', event?.indexPage)
        this.$set(this.thirdPartySignatureDialogConfig, 'show', true)
      }
    }
  }
}

import Vue from 'vue'
import App from './App.vue'
import './global/loaders/global-components'
import router from './global/router.js'
import store from './global/store.js'
import vuetify from './global/plugins/vuetify'
import translator from './global/plugins/translator'
import { install as useErrorHandler } from './global/plugins/error-reporting'
import { init as initApiClient } from './global/services/api'
import stripped from '@/global/directives/stripped'
import resizeText from '@/global/directives/resizeText'
import VueFlags from '@growthbunker/vueflags'

Vue.config.productionTip = false
////////////////
Vue.config.devtools = true
//////////

useErrorHandler(Vue)

Vue.use(translator, store)
Vue.use(VueFlags, {
  // For adding new flags download 4x3 flags from https://github.com/lipis/flag-icons/tree/main/flags and add them to /public/img/icons/flags
  iconPath: '/img/icons/flags'
})

initApiClient(store)

Vue.directive('stripped', stripped)
Vue.directive('resizeText', resizeText)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

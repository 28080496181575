import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
const tz = dayjs.tz.guess()

export const defaultDateFormat = 'DD.MM.YYYY'
export const defaultTimeFormat = 'HH:mm:ss'
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

export const getEnglishLocaleCodes = () => [
  'en-au',
  'en-bz',
  'en-ca',
  'en-cb',
  'en-gb',
  'en-in',
  'en-ie',
  'en-jm',
  'en-nz',
  'en-ph',
  'en-za',
  'en-tt',
  'en-us'
]

export const dateDifferenceByTypes = () => [
  'day',
  'week',
  'Quarter',
  'Month',
  'year',
  'hour',
  'minute',
  'second',
  'millisecond'
]

export function formatSqlDate (date, format = defaultDateFormat) {
  return dayjs(date)
    .tz(tz)
    .format(format)
}
export function getDateDifference (date1, date2, differenceBy, format) {
  const date1Formatted = date1 && format ? dayjs(date1, format) : dayjs(date1)
  const date2Formatted = date2 && format ? dayjs(date2, format) : dayjs(date2)

  return date1Formatted.isValid() && date2Formatted.isValid() && differenceBy && dateDifferenceByTypes().includes(differenceBy)
    ? date2Formatted.diff(date1Formatted, differenceBy)
    : null
}

export function convertDate (date = '', fromFormat = '', toFormat = '') {
  if (fromFormat && toFormat && date) {
    return dayjs(date, fromFormat).format(toFormat)
  }
}

export function currentDate (format = defaultDateFormat) {
  return dayjs().format(format)
}

export function currentTime (format = defaultTimeFormat) {
  return dayjs().format(format)
}

export function formatSqlTime (date) {
  return dayjs(date)
    .tz(tz)
    .format(defaultTimeFormat)
}

export function validateDateTimeFormat (dateString, format = defaultDateTimeFormat) {
  return dayjs(dateString, format, true).isValid()
}

export function formatTime (date, format = 'HH:mm') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatSqlDateTime (dateTime, format = null) {
  const localeDateTimeFormat = !format ? `${defaultDateFormat} ${defaultTimeFormat}` : format

  return dayjs(dateTime)
    .tz(tz)
    .format(localeDateTimeFormat)
}

export function formatIsoDate (date, format = 'YYYY-MM-DD') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatIsoDateCustom (date, fromFormat = undefined, toFormat = 'YYYY-MM-DD') {
  return dayjs(date, fromFormat)
    .tz(tz)
    .format(toFormat)
}

export function formatIsoTime (date, format = 'HH:mm:ss') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatIsoString (date) {
  return dayjs(date).toISOString()
}

export function parseDmyDate (date) {
  return dayjs(date, defaultDateFormat)
    .format('YYYY-MM-DD')
}

export function parseDmyDateTime (dateTime) {
  const dateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

  return dayjs(dateTime, dateTimeFormat)
    .format('YYYY-MM-DD HH:mm:ss')
}

import { isObject } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'tableConfig',

  data () {
    return {
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        current_status: null,
        document_id: null,
        callback: null
      },
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      }
    }
  },

  computed: {
    tableConfig () {
      return {
        tableConfig: {
          apiConfig: {
            get: {
              module: 'fleet',
              route: 'travel-documents/prl-cargo',
              method: 'get'
            }
          },
          rowClick: (event) => this.showPrlCargoDetails(event),
          search_and_buttons_config: {
            newClick: (event) => this.showCreateNewPrlCargo(event)
          },
          title_and_back_config: {
            title: this.$t('fleet.prl_cargo')
          },
          tableConfig: {
            styles: {
              useFixedHeaderWidth: true,
              headersHeight: '76px',
              dataRowHeight: '64px'
            },
            cellsConfig: {
              driver: {
                custom_display_value: (data) => {
                  return data && data.driver && data.driver.fullName ? data.driver.fullName : '-'
                }
              },
              status: {
                type: 'status',
                custom_display_value: (data) => {
                  if (data && data.status && isObject(data.status)) {
                    return {
                      ...data.status,
                      ...{
                        description: data.status.description ? this.$t(data.status.description) : '-'
                      }
                    }
                  }
                },
                displayValue: 'description',
                colorObjectKey: 'color',
                textColorKey: 'text',
                backgroundColorKey: 'background',
                style: {
                  textAlign: '-webkit-center'
                },
                statusStyle: {
                  textAlign: 'center',
                  borderRadius: '8px',
                  height: '24px',
                  paddingRight: '12px',
                  paddingLeft: '12px',
                  width: 'max-content'
                }
              }
            },
            headers: [
              {
                label: this.$t('fleet/documents/prl_cargo.sr_no'),
                key: 'id',
                style: {
                  width: '100px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.prl_no'),
                key: 'foreign_code',
                style: {
                  width: '130px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.creation_date'),
                key: 'date_time',
                style: {
                  width: '155px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.driver'),
                key: 'driver_name',
                style: {
                  width: '172px'
                }
              },
              {
                label: this.$t('fleet/vehicle.label'),
                key: 'vehicle_brand',
                style: {
                  width: '172px'
                }
              },
              {
                label: this.$t('fleet/vehicle.registration'),
                key: 'vehicle_registration',
                style: {
                  width: '172px'
                }
              },
              {
                label: this.$t('fleet/vehicle_attribute.garage_number'),
                key: 'vehicle_garage_number',
                style: {
                  width: '172px'
                }
              },
              {
                label: this.$t('fleet.organizational_unit'),
                key: 'organizational_unit',
                style: {
                  width: '160px'
                }
              },
              {
                label: this.$t('fleet.department'),
                key: 'department',
                style: {
                  width: '130px'
                }
              },
              {
                label: this.$t('fleet/vehicle.status'),
                key: 'status',
                style: {
                  width: '200px',
                  textAlign: 'center'
                },
                always_visible: true
              }
            ],
            paginationConfig: {
              perPageOptions: [20, 50, 100],
              perPage: 20,
              currentPage: 1
            },
            actionsConfig: [
              {
                label: this.$t('base.edit'),
                icon: 'mdi-pencil-box',
                click: (event) => this.showPrlCargoDetails(event)
              },
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.change_status'),
                icon: 'mdi-swap-horizontal',
                visible: (event) => {
                  const { data } = event || {}
                  const { canChangeStatus } = data?.permissions || {}

                  return !!canChangeStatus
                },
                click: (event) => this.onTableChangeStatus(event)
              },
              {
                label: this.$t('base.delete'),
                icon: 'mdi-delete',
                visible: (event) => {
                  const { data } = event || {}
                  const { canDelete } = data?.permissions || {}

                  return !!canDelete
                },
                click: (event) => this.onDocumentDelete(event)
              }
            ],
            filtersConfig: {
              fields: [
                {
                  key: 'id',
                  label: this.$t('fleet/documents/prl_cargo.sr_no')
                },
                {
                  key: 'foreign_code',
                  label: this.$t('fleet/documents/prl_cargo.prl_no')
                },
                {
                  key: 'date_time',
                  label: this.$t('fleet/documents/prl_cargo.creation_date')
                },
                {
                  key: 'driver_name',
                  label: this.$t('fleet/vehicle.driver')
                },
                {
                  key: 'vehicle_brand',
                  label: this.$t('fleet/vehicle.label'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/get-vehicle',
                    custom_display_value: (value) => {
                      return value && value.vehicle_brand ? value.vehicle_brand : null
                    },
                    additionalQueryParams: {
                      by: 'registration',
                      vehicle_type: 'vehicle',
                      document_type: 'PRL_CARGO'
                    },
                    form_value: 'vehicle_brand'
                  }
                },
                {
                  key: 'vehicle_registration',
                  label: this.$t('fleet/vehicle.registration'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/get-vehicle',
                    custom_display_value: (value) => {
                      return value && value.vehicle_registration ? value.vehicle_registration : null
                    },
                    additionalQueryParams: {
                      by: 'registration',
                      vehicle_type: 'vehicle',
                      document_type: 'PRL_CARGO'
                    },
                    form_value: 'vehicle_registration'
                  }
                },
                {
                  key: 'vehicle_garage_number',
                  label: this.$t('fleet/vehicle_attribute.garage_number'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/get-vehicle',
                    custom_display_value: (value) => {
                      return value && value.vehicle_garage_number ? value.vehicle_garage_number : null
                    },
                    additionalQueryParams: {
                      by: 'garage_number',
                      vehicle_type: 'vehicle',
                      document_type: 'PRL_CARGO'
                    },
                    form_value: 'vehicle_garage_number'
                  }
                },
                {
                  key: 'organizational_unit',
                  label: this.$t('fleet.organizational_unit')
                },
                {
                  key: 'department',
                  label: this.$t('fleet.department')
                },
                {
                  key: 'status',
                  label: this.$t('fleet/vehicle.status'),
                  type: 'autocomplete',
                  autocomplete_options: {
                    module: 'fleet',
                    route: 'travel-documents/statuses',
                    custom_display_value: (value) => {
                      const { description } = value || null
                      return description && typeof description === 'string' ? this.$t(description) : ''
                    },
                    form_value: 'id'
                  }
                }
              ]
            }
          }
        },
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    showPrlCargoDetails (event) {
      const { data } = event || {}
      const { id, foreign_code: foreignCode } = data || {}

      if (id) {
        this.$router.push({
          name: 'PrlCargoDetailsView',
          params: {
            id: id
          },
          query: {
            foreign_code: foreignCode ?? this.$t('fleet/documents/prl_cargo.document_edit')
          }
        }).then()
      }
    },

    redirectToEdit (event, data) {
      const { id, foreign_code: foreignCode } = data || {}

      if (id) {
        this.$router.push({
          name: 'PrlCargoDetailsView',
          params: {
            id: id
          },
          query: {
            foreign_code: foreignCode ?? this.$t('fleet/documents/prl_cargo.document_edit')
          }
        }).then()
      }
    },

    async onTableChangeStatus (event) {
      const { id } = event?.data?.status || {}
      const { id: documentId } = event?.data || {}
      const { fetchTableData } = event || {}

      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'current_status', id)
        this.$set(this.changeStatusDialogConfig, 'document_id', documentId)

        if (typeof fetchTableData === 'function') {
          this.$set(this.changeStatusDialogConfig, 'callback', () => fetchTableData())
        }

        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'current_status', id)
            this.$set(this.changeStatusDialogConfig, 'document_id', documentId)
            if (typeof fetchTableData === 'function') {
              this.$set(this.changeStatusDialogConfig, 'callback', () => fetchTableData())
            }
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    async closeChangeStatusDialog () {
      this.$set(this.changeStatusDialogConfig, 'show', false)
    },

    showCreateNewPrlCargo (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage()
      }
    },

    async onDocumentDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.delete_document_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/vehicle/documents_tab.delete_dialog_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.deleteDocumentCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async deleteDocumentCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchTableData } = event || {}

        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${id}`)

          // Close confirmation dialog
          this.$set(this.informationDialogData, 'show', false)

          // Refresh table data after successfully document delete
          if (typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    }
  }
}

import { currentDate, currentTime } from '@/global/services/helpers/dates'
import { api } from '@/global/services/api'
import common from '../common'

export default {
  name: 'fuelConfig',

  data () {
    return {
      fuelPermissions: {}
    }
  },

  mixins: [
    common
  ],

  computed: {
    fuel () {
      return {
        title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
        key: 'fuel',
        tabContent: [
          {
            title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
            component: this.tableGridContentComponent,
            rowClick: (event) => this.onShowFuelDetailsPage(event, 'showTableDetailsPage'),
            apiConfig: {
              get: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/${this.$route.params.id}/fuel`,
                method: 'get'
              }
            },
            openDetail: {
              id: this.$route.query.item_id ?? null,
              tabKey: 'fuel',
              config: this.fuelLubricantDetails
            },
            permissions: this.fuelPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              const { status } = data || null
              this.currentStatusId = status
              this.$set(this.fuelPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
              this.$set(this.fuelPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : true)
            },
            search_and_buttons_config: {
              additional_buttons: [
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                  prependIcon: 'mdi-file-pdf-box',
                  click: (event) => this.onFuelViewPdf(event)
                },
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.change_status'),
                  prependIcon: 'mdi-swap-horizontal',
                  visible: (event) => {
                    const { permissions } = event || {}

                    return permissions && permissions.canChangeStatus
                  },
                  click: (event) => this.onChangeStatus(event, 'fuel')
                }
              ],
              newClick: (event) => this.onShowNewFuelPage(event, 'showTableNewPage')
            },
            title_and_back_config: {
              title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
              showBackButton: {
                uiViewName: 'PrlCargo'
              }
            },
            filters_and_columns_visibility_config: {
              showRefreshButton: false,
              showFiltersButton: false,
              showColumnsVisibilityButton: false
            },
            tableConfig: {
              styles: {
                useFixedHeaderWidth: true,
                dataRowHeight: '64px',
                headersHeight: '60px'
              },
              cellsConfig: {
                location: {
                  custom_display_value: (data) => {
                    return data && data.location && data.location.value ? data.location.value : '-'
                  }
                },
                partner: {
                  custom_display_value: (data) => {
                    return data && data.partner && data.partner.name ? data.partner.name : '-'
                  }
                },
                fuel_signature_image: {
                  type: 'image',
                  style: {
                    textAlign: 'center'
                  }
                },
                date: {
                  style: {
                    textAlign: 'center'
                  }
                },
                time: {
                  style: {
                    textAlign: 'center'
                  }
                },
                amount: {
                  style: {
                    textAlign: 'center'
                  }
                },
                account_number: {
                  style: {
                    textAlign: 'center'
                  }
                },
                fuel_signed_at: {
                  style: {
                    textAlign: 'center'
                  }
                }
              },
              headers: [
                {
                  label: this.$t('fleet/documents/prl_cargo.sr_no'),
                  key: 'id',
                  style: {
                    width: '80px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.date'),
                  key: 'date',
                  style: {
                    width: '130px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.time'),
                  key: 'time',
                  style: {
                    width: '130px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.place'),
                  key: 'location',
                  style: {
                    width: '160px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.issuer_of_fuel'),
                  key: 'partner',
                  style: {
                    width: '190px'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.bill_amount'),
                  key: 'amount',
                  style: {
                    width: '135px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.account_number'),
                  key: 'account_number',
                  style: {
                    width: '130px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.signature_of_the_issuer'),
                  key: 'fuel_signature_image',
                  style: {
                    width: '220px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.issuer_signature_time'),
                  key: 'fuel_signed_at',
                  style: {
                    width: '180px',
                    textAlign: 'center'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.name_and_surname_of_the_signature'),
                  key: 'fuel_signature_name',
                  style: {
                    width: '180px'
                  },
                  always_visible: true
                }
              ],
              actionsConfig: [
                {
                  label: this.$t('base.edit'),
                  icon: 'mdi-pencil-box',
                  click: (event) => this.onShowFuelDetailsPage(event, 'showTableDetailsPage')
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.send_for_signature'),
                  icon: 'mdi-send',
                  disabled: (event) => {
                    const { permissions } = event || {}

                    return permissions && !permissions.canEdit
                  },
                  click: (event) => this.onFuelSendForSignature(event)
                },
                {
                  label: this.$t('base.delete'),
                  icon: 'mdi-delete',
                  disabled: (event) => {
                    const { permissions } = event || {}

                    return permissions && !permissions.canEdit
                  },
                  click: (event) => this.onFuelDelete(event)
                }
              ]
            }
          }
        ]
      }
    },

    fuelLubricantDetails () {
      return {
        title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant_data'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/prl-cargo/${this.$route.params.id}/fuel`
              }
              else {
                return data && data.id ? `travel-documents/prl-cargo/${this.$route.params.id}/fuel/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        fileKeys: [
          'signature_image'
        ],
        tabContent: [
          {
            type: 'columns',
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            fullWidth: true,
            insideCardButtons: {
              position: 'right',
              buttons: [
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                  },
                  visible: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_can_sign
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  color: this.$route.query.sign_button_color ?? '',
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.fuelsign(event, 'PRL_CARGO_FUEL_ISSUER', true)
                },
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                  },
                  visible: () => {
                    const { canEdit } = this.fuelPermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? 'mdi-check' : 'mdi-send'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onFuelSendForSignature(event)
                },
                {
                  type: 'base',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.third_party_signature')
                  },
                  visible: (event) => {
                    const { canEdit } = this.fuelPermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image
                  },
                  color: '#65AD45',
                  style: {
                    color: '#FFFFFF'
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onFuelThirdPartySignature(event)
                }
              ]
            },
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'date',
                    label: this.$t('fleet/documents/prl_cargo.date'),
                    type: 'datetime-picker',
                    subType: 'date',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY.',
                    placeholder: 'DD.MM.YYYY.',
                    initialValue: (event) => {
                      const { createMode } = event?._props || {}
                      if (createMode) {
                        return currentDate('DD.MM.YYYY.')
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'time',
                    label: this.$t('fleet/documents/prl_cargo.time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-calendar-month',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    initialValue: (event) => {
                      const { createMode } = event?._props || {}
                      if (createMode) {
                        return currentTime('HH:mm')
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'location',
                    label: this.$t('fleet/documents/prl_cargo.place'),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/locations/autocomplete',
                      returnValue: 'object',
                      custom_display_value: (item) => {
                        return item && item.value ? item.value : null
                      },
                      form_value: 'value'
                    },
                    keepInput: (input) => {
                      return {
                        value: input,
                        items: input ? [
                          {
                            value: input
                          }
                        ] : []
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'partner',
                    label: this.$t('fleet/documents/prl_cargo.issuer_of_fuel'),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'administration',
                      route: 'partners/autocomplete',
                      returnValue: 'object',
                      returnValueProps: ['id', 'name']
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'account_number',
                    label: this.$t('fleet/documents/prl_cargo.account_number'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'amount',
                    label: this.$t('fleet/documents/prl_cargo.bill_amount'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'fuel_signature_name',
                    label: this.$t('fleet/documents/prl_cargo.issuer_name_and_surname'),
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  },
                  {
                    key: 'fuel_signed_at',
                    label: this.$t('fleet/documents/prl_cargo.issuer_signature_time'),
                    placeholder: 'DD.MM.YYYY HH:mm',
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  },
                  {
                    type: 'content-with-buttons',
                    key: 'fuel_signature_image',
                    label: this.$t('fleet/documents/prl_cargo.signature_of_the_issuer'),
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px'
                    },
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  }
                ]
              }
            ]
          },
          {
            title: this.$t('fleet/documents/prl_cargo.cost_information'),
            type: 'dynamic-columns',
            key: 'invoice_items',
            display_props: ['product', 'quantity', 'fuel_type'],
            showInitialRows: 1,
            fullWidth: true,
            headers: [
              this.$t('fleet/documents/prl_cargo.article'),
              this.$t('fleet/documents/prl_cargo.amount'),
              this.$t('fleet/documents/prl_cargo.type_of_fuel_lubricant')
            ],
            fields: [
              {
                key: 'product',
                type: 'autocomplete',
                placeholder: this.$t('fleet/documents/prl_cargo.article_placeholder'),
                autocomplete_options: {
                  module: 'fleet',
                  route: 'travel-documents/fuel-product/autocomplete',
                  returnValue: 'object',
                  form_value: 'product.value',
                  custom_display_value: (item) => {
                    return item && item.value ? item.value : null
                  }
                },
                keepInput: (input) => {
                  return {
                    value: input,
                    items: input ? [
                      {
                        value: input
                      }
                    ] : []
                  }
                },
                creatable: true,
                editable: true,
                visible: true
              },
              {
                key: 'quantity',
                placeholder: this.$t('fleet/documents/prl_cargo.amount_placeholder'),
                field_type: 'number',
                creatable: true,
                editable: true,
                visible: true
              },
              {
                key: 'fuel_type',
                type: 'autocomplete',
                placeholder: this.$t('fleet/documents/prl_cargo.fuel_type_placeholder'),
                label: this.$t('base/contact_type.type'),
                autocomplete_options: {
                  module: 'fleet',
                  route: 'travel-documents/fuel-item-type/autocomplete',
                  custom_display_value: (item) => {
                    if (item && item.description) {
                      return this.$t(item.description)
                    }
                  },
                  allowSearch: false,
                  returnValue: 'object'
                },
                creatable: true,
                editable: true,
                visible: true
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onShowFuelDetailsPage (event, functionName) {
      const { index, instance } = event

      if (functionName && instance && typeof instance[functionName] === 'function') {
        instance[functionName](index, this.fuelLubricantDetails)
      }
    },

    onShowNewFuelPage (event, functionName) {
      if (functionName && event && typeof event[functionName] === 'function') {
        event[functionName](this.fuelLubricantDetails)
      }
    },

    // TODO: Implement pdf view
    onFuelViewPdf (event) {},

    onFuelDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('base.delete_data_popup_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('base.delete_data_popup_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onFuelDeleteCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async onFuelDeleteCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchTableData } = event || {}
        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/fuel/${id}`)
          this.$set(this.informationDialogData, 'show', false)

          if (fetchTableData && typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    onFuelSendForSignature (event) {
      if (event && event.formData) {
        this.$set(this.sendForSignatureDialogData, 'additionalObject', {
          foreign_id: event?.formData?.id
        })
        this.$set(this.sendForSignatureDialogData, 'instance', event?.instance?.$parent?.$parent?.$parent)
      }
      else if (event && event.data) {
        this.$set(this.sendForSignatureDialogData, 'additionalObject', {
          foreign_id: event?.data?.id
        })
        this.$set(this.sendForSignatureDialogData, 'instance', event?.instance)
      }

      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_FUEL_ISSUER')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    onFuelThirdPartySignature (event) {
      const { id } = event?.formData || {}
      this.$set(this.thirdPartySignatureDialogConfig, 'title', this.$t('fleet/documents/prl_cargo.add_signatory_information'))
      this.$set(this.thirdPartySignatureDialogConfig, 'signatureType', 'PRL_CARGO_FUEL_ISSUER')
      this.$set(this.thirdPartySignatureDialogConfig, 'apiConfig', {
        module: 'fleet',
        method: 'post',
        route: 'travel-documents/third-party-signature'
      })
      if (id) {
        this.$set(this.thirdPartySignatureDialogConfig, 'additionalObject', {
          foreign_id: id
        })
      }
      this.$set(this.thirdPartySignatureDialogConfig, 'instance', event?.instance?.$parent?.$parent?.$parent)
      this.$set(this.thirdPartySignatureDialogConfig, 'show', true)
    },

    fuelsign (event, signatureType = '', fetchSignature = false) {
      if (event && event.formData) {
        this.$set(this.signatureDialogData, 'additionalObject', {
          foreign_id: event?.formData?.id
        })
      }
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.instance?.$parent?.$parent?.$parent)
      this.$set(this.signatureDialogData, 'show', true)
    }
  }
}

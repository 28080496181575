import { currentDate, currentTime } from '@/global/services/helpers/dates'
import common from '../common'

const columnsContentComponent = () => import('@/global/components/view-layouts/tabs-layout/components/tab-content-types/ColumnsContent.vue')
export default {
  name: 'createConfig',

  mixins: [
    common
  ],

  computed: {
    createDetailsConfig () {
      return {
        tabs: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: 'travel-documents/disposition',
                method: 'post'
              }
            },
            hiddenFormKeys: [
              'vehicle_id',
              'trailer_id'
            ],
            afterSave: (event, data) => this.redirectToEdit(event, data),
            tabContent: [
              // Document data card
              {
                title: this.$t('fleet/vehicle.basic_info'),
                subTitle: this.$t('fleet/documents/machine.document_data'),
                component: columnsContentComponent,
                showButtons: true,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'foreign_code',
                        row: 1,
                        column: 1,
                        label: this.$t('fleet/documents/machine.machine_operation_document_number'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'date_time',
                        row: 1,
                        column: 2,
                        label: this.$t('fleet/documents/prl_cargo.date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        initialValue: currentDate('DD.MM.YYYY.') + ' ' + currentTime('HH:mm'),
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'status_name',
                        row: 1,
                        column: 3,
                        label: this.$t('fleet/vehicle.status'),
                        initialValue: 'U obradi',
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'created_at',
                        row: 1,
                        column: 4,
                        initialValue: currentDate('DD.MM.YYYY.') + ' ' + currentTime('HH:mm'),
                        label: this.$t('fleet/documents/machine.document_creation_time'),
                        creatable: true,
                        editable: false,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Vehicle information card
              {
                subTitle: this.$t('fleet/documents/disposition.vehicle_information'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 3,
                    fields: [
                      {
                        key: 'vehicle_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'vehicle_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (value) => {
                            return value && value.vehicle_registration ? value.vehicle_registration : null
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'vehicle',
                            document_type: 'DISPOSITION'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'vehicle_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (value) => {
                            return value && value.vehicle_garage_number ? value.vehicle_garage_number : null
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'vehicle',
                            document_type: 'DISPOSITION'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'trailer_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (item) => {
                            if (item && item.trailer_registration) {
                              return item.trailer_registration
                            }
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'trailer',
                            document_type: 'DISPOSITION'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (item) => {
                            if (item && item.trailer_garage_number) {
                              return item.trailer_garage_number
                            }
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'trailer',
                            document_type: 'DISPOSITION'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Customer information card
              {
                subTitle: this.$t('fleet/documents/disposition.additional_information'),
                component: columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'client_name',
                        label: this.$t('fleet/documents/disposition.customer'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'construction_site',
                        label: this.$t('fleet/documents/machine.construction_site_drive'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'section',
                        label: this.$t('fleet/documents/machine.section'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'cost_location',
                        label: this.$t('fleet/documents/prl_cargo.cost_location'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'starting_point',
                        label: this.$t('fleet/documents/disposition.delivery_from'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'ending_point',
                        label: this.$t('fleet/documents/disposition.delivery_to'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'supplier',
                        label: this.$t('fleet/documents/disposition.supplier'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'travel_document_prl_id',
                        label: this.$t('fleet/documents/disposition.travel_order_number'),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/prl-cargo/autocomplete',
                          custom_display_value: (value) => {
                            const { foreign_code: foreignCode } = value || null
                            return foreignCode && typeof foreignCode === 'string' ? foreignCode : ''
                          },
                          form_value: 'id'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: this.$t('fleet/documents/disposition.vehicle_operation_data'),
            disabled: true
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.disposition')
            },
            {
              text: this.$t('fleet/documents/disposition.adding_new_vehicle_operation_data'),
              bolded: true
            }
          ]
        }
      }
    }
  }
}

import { isObject } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'common',

  data () {
    return {
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        document_id: null,
        callback: null,
        currentStatusId: null
      }
    }
  },

  methods: {
    updateFormData (updateFunction, props = {}) {
      if (updateFunction && typeof updateFunction === 'function') {
        updateFunction(props)
      }
    },

    async onVehicleRegistrationSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          transport_type: '',
          organizational_unit: '',
          department: '',
          vehicle_brand: '',
          vehicle_garage_number: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          transport_type: selectedItem.transport_type || '',
          organizational_unit: selectedItem.organizational_unit ?? '',
          department: selectedItem.department || '',
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            pickedId: selectedItem?.id,
            by: 'garage_number',
            vehicle_type: 'vehicle',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_garage_number: selectedItem.vehicle_garage_number || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_garage_number: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onVehicleGarageNumberSelect (event) {
      const { instance, selectedItem, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          transport_type: '',
          organizational_unit: '',
          department: '',
          vehicle_brand: '',
          vehicle_registration: '',
          vehicle_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          transport_type: selectedItem.transport_type || '',
          organizational_unit: selectedItem.organizational_unit ?? '',
          department: selectedItem.department || '',
          vehicle_brand: selectedItem.vehicle_brand || '',
          vehicle_id: selectedItem.id || null
        }

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_registration) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            pickedId: selectedItem?.id,
            by: 'registration',
            vehicle_type: 'vehicle',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_registration', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              vehicle_registration: selectedItem.vehicle_registration || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            vehicle_registration: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onTrailerRegistrationSelect (event) {
      const { selectedItem, instance, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: '',
          trailer_garage_number: '',
          trailer_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: selectedItem.trailer_brand || '',
          trailer_id: selectedItem.id || null
        }

        if (selectedItem.trailer_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            pickedId: selectedItem?.id,
            by: 'garage_number',
            vehicle_type: 'trailer',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              trailer_garage_number: selectedItem.trailer_garage_number || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            trailer_garage_number: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    async onTrailerGarageNumberSelect (event) {
      const { selectedItem, instance, searchInput, updateData } = event || {}
      let propsForUpdate = {}

      if (!searchInput && !selectedItem) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: '',
          trailer_registration: '',
          trailer_id: null
        }
      }
      else if (selectedItem && isObject(selectedItem)) {
        propsForUpdate = {
          ...propsForUpdate,
          trailer_brand: selectedItem.trailer_brand || '',
          trailer_id: selectedItem.id || null
        }

        if (selectedItem.trailer_registration) {
          const { data: registrationData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: '',
            pickedId: selectedItem?.id,
            by: 'registration',
            vehicle_type: 'trailer',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_registration', {
              data: registrationData,
              hasMore: hasMore
            })

            propsForUpdate = {
              ...propsForUpdate,
              trailer_registration: selectedItem.trailer_registration || ''
            }
          }
        }
        else {
          propsForUpdate = {
            ...propsForUpdate,
            trailer_registration: ''
          }
        }
      }

      this.updateFormData(updateData, propsForUpdate)
    },

    handleAdditionalDataManipulation (data) {
      const {
        crew_member_1: crewMember1,
        crew_member_2: crewMember2,
        crew_member_3: crewMember3
      } = data || {}

      const crewMemberIds = [crewMember1, crewMember2, crewMember3].filter(Boolean)

      // Add the array only if at least one member exists
      if (crewMemberIds.length) {
        data.crew_members = crewMemberIds
        this.$delete(data, 'crew_member_1')
        this.$delete(data, 'crew_member_2')
        this.$delete(data, 'crew_member_3')
      }
    },

    async onChangeStatus (event, tabKey = '') {
      let currentFetchedStatusId = null

      if (this.currentStatusId) {
        currentFetchedStatusId = this.currentStatusId
      }

      if (event?.formData?.status) {
        currentFetchedStatusId = event.formData.status.id
      }

      if (event?.data?.status) {
        currentFetchedStatusId = event.data.status
      }

      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
        if (currentFetchedStatusId) {
          this.$set(this.changeStatusDialogConfig, 'currentStatusId', currentFetchedStatusId)
        }
        this.$set(this.changeStatusDialogConfig, 'callback', () => this.changeStatusCallback(tabKey))
        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
            if (currentFetchedStatusId) {
              this.$set(this.changeStatusDialogConfig, 'currentStatusId', currentFetchedStatusId)
            }
            this.$set(this.changeStatusDialogConfig, 'callback', () => this.changeStatusCallback(tabKey))
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    changeStatusCallback (tabKey) {
      const tabsForRefreshLocal = []
      // This tab should be always refreshable because of status display
      const alwaysRefreshTab = 'basic-info'

      if (tabKey && typeof tabKey === 'string') {
        tabsForRefreshLocal.push(tabKey)
        if (tabKey !== alwaysRefreshTab) {
          tabsForRefreshLocal.push(alwaysRefreshTab)
        }
      }

      this.tabsForRefresh = tabsForRefreshLocal
    },

    async closeChangeStatusDialog () {
      this.$set(this.changeStatusDialogConfig, 'show', false)
    }
  }
}
